<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3">
    <MDBCardBody>
      <MDBContainer class="mt-3" bg="white">
        <transition name="fade">
          <PageLoading v-if="PageLoading"></PageLoading>
        </transition>
        <MDBRow>
          <MDBCol>
            <MDBCard>
              <MDBCardBody>
                <Form @submit="onSubmit" :initial-values="Form">
                  <MDBCardText>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.CUSTOMERSERVICE") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="customer_service"
                          :label="$t('SERVICES.CUSTOMERSERVICE')"
                          :rules="customerServiceUrlRules"
                        />
                        <ErrorMessage name="customer_service" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="customer_service"
                              :checked="Form.customer_service_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.FB") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="fb_messager"
                          :label="$t('SERVICES.FB')"
                          :rules="facebookUrlRules"
                        />
                        <ErrorMessage name="fb_messager" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="fb_messager"
                              :checked="Form.fb_messager_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.LINE") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="line_uri"
                          :label="$t('SERVICES.LINE')"
                          :rules="lineUrlRules"
                        />
                        <ErrorMessage name="line_uri" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="line_uri"
                              :checked="Form.line_uri_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.WECHAT") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="wechat_uri"
                          :label="$t('SERVICES.WECHAT')"
                          :rules="wechatRules"
                        />
                        <ErrorMessage name="wechat_uri" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="wechat_uri"
                              :checked="Form.wechat_uri_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.QQ") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="qq_uri"
                          :label="$t('SERVICES.QQ')"
                          :rules="qqRules"
                        />
                        <ErrorMessage name="qq_uri" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="qq_uri"
                              :checked="Form.qq_uri_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.YOUTUBE") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="youtube_uri"
                          :label="$t('SERVICES.YOUTUBE')"
                          :rules="youtubeRules"
                        />
                        <ErrorMessage name="youtube_uri" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="youtube_uri"
                              :checked="Form.youtube_uri_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.TWITTER") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="twitter_uri"
                          :label="$t('SERVICES.TWITTER')"
                          :rules="twitterRules"
                        />
                        <ErrorMessage name="twitter_uri" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="twitter_uri"
                              :checked="Form.twitter_uri_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.FACEBOOK") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="facebook_uri"
                          :label="$t('SERVICES.FACEBOOK')"
                          :rules="facebookRules"
                        />
                        <ErrorMessage name="facebook_uri" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="facebook_uri"
                              :checked="Form.facebook_uri_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.LINKEDIN") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="linkedin_uri"
                          :label="$t('SERVICES.LINKEDIN')"
                          :rules="linkedinRules"
                        />
                        <ErrorMessage name="linkedin_uri" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="linkedin_uri"
                              :checked="Form.linkedin_uri_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>{{ $t("SERVICES.WHATSAPP") }}</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="whatsapp_uri"
                          :label="$t('SERVICES.WHATSAPP')"
                          :rules="whatsappRules"
                        />
                        <ErrorMessage name="whatsapp_uri" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="whatsapp_uri"
                              :checked="Form.whatsapp_uri_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>Line ID</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="line_id"
                          label="Line ID"
                          :rules="whatsappRules"
                        />
                        <ErrorMessage name="line_id" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="line_id"
                              :checked="Form.line_id_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>whatapps ID</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="whatapps_id"
                          label="whatapps ID"
                          :rules="whatsappRules"
                        />
                        <ErrorMessage name="whatapps_id" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="whatapps_id"
                              :checked="Form.whatapps_id_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>wechat ID</label>
                      </MDBCol>
                      <MDBCol md="8">
                        <Field
                          class="col-md-12 form-control"
                          name="wechat_id"
                          label="wechat ID"
                          :rules="whatsappRules"
                        />
                        <ErrorMessage name="wechat_id" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="wechat_id"
                              :checked="Form.wechat_id_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>Line Qrcode</label>
                      </MDBCol>
                      <MDBCol md="2">
                        <img :src="(url+Form.line_qr)" class="img-fluid">
                      </MDBCol>
                      <MDBCol md="6">
                        <Field
                          class="col-md-12 form-control"
                          name="line_qr_file"
                          label="Line Qrcode"
                          type="file"
                        />
                        <ErrorMessage name="line_qr_file" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="line_qr"
                              :checked="Form.line_qr_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>whatapps Qrcode</label>
                      </MDBCol>
                      <MDBCol md="2">
                        <img :src="(url+Form.whatapps_qr)" class="img-fluid">
                      </MDBCol>
                      <MDBCol md="6">
                        <Field
                          class="col-md-12 form-control"
                          name="whatapps_qr_file"
                          label="whatapps Qrcode"
                          type="file"
                        />
                        <ErrorMessage name="whatapps_qr_file" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="whatapps_qr"
                              :checked="Form.whatapps_qr_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="2">
                        <label>wechat Qrcode</label>
                      </MDBCol>
                      <MDBCol md="2">
                        <img :src="(url+Form.wechat_qr)" class="img-fluid">
                      </MDBCol>
                      <MDBCol md="6">
                        <Field
                          class="col-md-12 form-control"
                          name="wechat_qr_file"
                          label="wechat Qrcode"
                          type="file"
                        />
                        <ErrorMessage name="wechat_qr_file" class="text-danger" />
                      </MDBCol>
                      <MDBCol md="2">
                        <div class="mb-3">
                          <div class="form-switch mb-1">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-service="wechat_qr"
                              :checked="Form.wechat_qr_status"
                              @change="changeStatus"
                            />
                            {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardText>
                  <MDBRow>
                    <MDBCol>
                      <MDBBtn color="primary" type="submit">
                        {{ $t("COMMON.SAVE") }}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </Form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import { ref, reactive } from "vue";
import Swal from "sweetalert2";
import PageLoading from "@/components/Elements/PageLoading";
import ApiService from "@/core/services/api.service";
import { useI18n } from "vue-i18n";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import CommonService from "@/core/services/common.service";

export default {
  name: "services-manage",
  components: {
    MDBContainer,
    Breadcrumbs,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    PageLoading,
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const PageLoading = ref(true);
    const uuid = "a1bf6f8b-0415-43f8-8272-e03937193484";
    const baseUrl = `Admin/v1/sysconfig/service`;
    const Form = reactive({});
    const i18n = useI18n();
    // 取得客服資訊
    const GetSettings = async () => {
      return await ApiService.get(baseUrl, uuid).then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;

          let keys = Object.keys(responseData);
          for(let i = 0; i < keys.length; i++) {
            Form[keys[i]] = responseData[keys[i]].uri;
            Form[`${keys[i]}_status`] = responseData[keys[i]].status;
          }

        }
      });
    };

    const customerServiceUrlRules = yup.string().label(i18n.t("SERVICES.CUSTOMERSERVICE"));
    const facebookUrlRules = yup.string().label(i18n.t("SERVICES.FB"));
    const lineUrlRules = yup.string().label(i18n.t("SERVICES.LINE"));
    const wechatRules = yup.string().label(i18n.t("SERVICES.WECHAT"));
    const qqRules = yup.string().label(i18n.t("SERVICES.QQ"));
    const youtubeRules = yup.string().label(i18n.t("SERVICES.YOUTUBE"));
    const twitterRules = yup.string().label(i18n.t("SERVICES.TWITTER"));
    const facebookRules = yup.string().label(i18n.t("SERVICES.FACEBOOK"));
    const linkedinRules = yup.string().label(i18n.t("SERVICES.LINKEDIN"));
    const whatsappRules = yup.string().label(i18n.t("SERVICES.WHATSAPP"));
    // 更新資料
    const onSubmit = async (values) => {
      const sendData = {
        customer_service: {
          uri: values.customer_service,
        },
        fb_messager: {
          uri: values.fb_messager,
        },
        line_uri: {
          uri: values.line_uri,
        },
        wechat_uri: {
          uri: values.wechat_uri,
        },
        qq_uri: {
          uri: values.qq_uri,
        },
        youtube_uri: {
          uri: values.youtube_uri,
        },
        twitter_uri: {
          uri: values.twitter_uri,
        },
        facebook_uri: {
          uri: values.facebook_uri,
        },
        linkedin_uri: {
          uri: values.linkedin_uri,
        },
        whatsapp_uri: {
          uri: values.whatsapp_uri,
        },
        line_id: {
          uri: values.line_id,
        },
        whatapps_id: {
          uri: values.whatapps_id,
        },
        wechat_id: {
          uri: values.wechat_id,
        },
        line_qr: {
          uri: values.line_qr,
        },
        whatapps_qr: {
          uri: values.line_qr,
        },
        wechat_qr: {
          uri: values.line_qr,
        },
        line_qr_file: {
          uri: values.line_qr_file,
        },
        whatapps_qr_file: {
          uri: values.whatapps_qr_file,
        },
        wechat_qr_file: {
          uri: values.wechat_qr_file,
        },
      };
      let form = CommonService.MakeForm(sendData);
      form.append('_method', 'PUT');
      // 更新資料
      return await ApiService.post(baseUrl+'/'+ uuid, form).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.EDIT")}${i18n.t("COMMON.SUCCESS")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });
        }
      });
    };

    // 更改狀態
    const changeStatus = () => {
      const choise = event.target.checked;
      const sendData = {};

      sendData[event.target.dataset.service] = { status: +choise };

      return ApiService.update(baseUrl, uuid, sendData).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.STATUS")}${i18n.t("COMMON.EDIT")}${i18n.t(
              "COMMON.SUCCESS"
            )}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });
        }
      });
    };

    const url = process.env.VUE_APP_API_URL;

    return {
      PageLoading,
      GetSettings,
      Form,
      onSubmit,
      changeStatus,
      facebookUrlRules,
      lineUrlRules,
      wechatRules,
      qqRules,
      youtubeRules,
      twitterRules,
      whatsappRules,
      linkedinRules,
      facebookRules,
      customerServiceUrlRules,
      url,
    };
  },
  mounted() {
    Promise.all([this.GetSettings()]).then(() => {
      this.PageLoading = false;
    });
  },
};
</script>
